import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { Home, HomeContent } from "./index-page";
import styled from "styled-components";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

const Title = styled.h1`
  padding: 10px 20px 0 20px;
  /* @media (max-width: 968px) {
    padding: 1rem;
  } */
  color: #27213c;
  margin-bottom: 0;
  font-size: 2rem;
  font-family: "Nexa";
  text-align: start;
  text-transform: uppercase;
`;
const Wrapper = styled.div`
  p {
    text-align: start;
    font-size: 14px;
    color: #27213c;
    padding: 0 20px 0 20px;
    /* @media (max-width: 968px) {
      padding: 1rem;
    } */
  }
`;
export const ProjectsSlider = styled.div`
  display: flex;
  place-items: center;
  /* margin: 1rem; */
  height: 200px;
  background: ${({ color }) => (color ? color : "transparent")};
  .BrainhubCarousel {
    height: 200px;
  }
  .BrainhubCarousel__arrows span {
    width: 15px;
    border-color: #9a1c1f;
  }
  .BrainhubCarousel__arrows {
    background: transparent;
  }
  .BrainhubCarousel__arrows:hover {
    background: transparent;
    span {
      border-color: #27213c;
    }
  }
`;
export const Code = styled.span`
  color: #9a1c1f;
  font-family: "Nexa";
  display: none;
  font-size: 12px;
`;
export const SlideWrap = styled.div`
  margin: 20px;
  /* height: 50%; */
  transition: all 0.2s ease-in-out;
  img {
    margin: 0;
  }
  :hover {
    transform: scale(1.3);
    margin: 0px 1rem;
    ${Code} {
      display: flex;
      justify-content: center;
    }
  }
`;
export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  title,
  helmet,
  products,
  color,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <Wrapper className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <Title className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </Title>
            <p>{description}</p>
            <ProjectsSlider color={color}>
              <Carousel
                arrows
                infinite
                slidesPerPage={5}
                breakpoints={{
                  640: {
                    slidesPerPage: 2,
                  },
                  900: {
                    slidesPerPage: 5,
                  },
                }}
              >
                {products &&
                  products.product?.map((project, index) => (
                    <SlideWrap
                      key={index}
                      className="gatsby-image-carousel-wrapper"
                    >
                      {/* //   <Overlay>
                //     <a */}
                      {/* //       href={project.link}
                //       target="_blank"
                //       rel="noreferrer"
                //       aria-label="project-link"
                //       key={index}
                //     > */}
                      <img src={project.image.childImageSharp?.fluid.src} />
                      <Code>{project.code}</Code>
                      {/* // <SvgBackground */}
                      {/* //   link={project.image.childImageSharp?.fluid.src}
                //   id={index}
                // />
                //     </a>
                //   </Overlay> */}
                      {/* //   <InfoPart>
                //     <span>{project.title}</span>
                //     <span>{project.description}</span>
                //   </InfoPart> */}
                    </SlideWrap>
                  ))}
              </Carousel>
            </ProjectsSlider>

            {/* <PostContent content={content} /> */}
          </div>
        </div>
      </Wrapper>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,

  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Home color="white" iconColor="yellow" side="">
        <HomeContent>
          <BlogPostTemplate
            content={post.html}
            contentComponent={HTMLContent}
            description={post.frontmatter.description}
            helmet={
              <Helmet titleTemplate="Service | %s">
                <title>{`${post.frontmatter.title}`}</title>
                <meta
                  name="description"
                  content={`${post.frontmatter.description}`}
                />
              </Helmet>
            }
            title={post.frontmatter.title}
            products={post.frontmatter.products}
            color={post.frontmatter.color}
          />
        </HomeContent>
      </Home>
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        color
        description
        products {
          product {
            image {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            code
            alt
          }
        }
      }
    }
  }
`;
